var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-management"},[_c('PageBread',{attrs:{"text":"我的优惠券","img":function () { return require('../../assets/image/zhuye.png'); }}}),_c('div',{staticClass:"center-order"},[_c('PageTab',{staticStyle:{"height":"calc(100vh - 200px)"},attrs:{"current":_vm.tabCurrent,"tabs":[
        {
          label: '未使用',
          value: 1,
        },
        {
          label: '已过期',
          value: 2,
        },
        {
          label: '已使用',
          value: 3,
        } ]},on:{"change":_vm.tabChange}},[_c('ScrollView',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabCurrent === 0),expression:"tabCurrent === 0"}],attrs:{"pageInfo":_vm.tab1PageInfo},on:{"loadNewData":function (pageInfo) { return _vm.tabLoad('1', pageInfo); }}},_vm._l((_vm.noUserList),function(item,index){return _c('div',{key:index,staticClass:"bottom-transaction"},[_c('p',[_vm._v(_vm._s(item.name))])])}),0),_c('ScrollView',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabCurrent === 1),expression:"tabCurrent === 1"}],attrs:{"pageInfo":_vm.tab2PageInfo},on:{"loadNewData":function (pageInfo) { return _vm.tabLoad('2', pageInfo); }}},_vm._l((_vm.expiredList),function(exo,jm){return _c('div',{key:jm,staticClass:"bottom-transaction"},[_c('p',[_vm._v(_vm._s(exo.name))])])}),0),_c('ScrollView',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabCurrent === 2),expression:"tabCurrent === 2"}],attrs:{"pageInfo":_vm.tab3PageInfo},on:{"loadNewData":function (pageInfo) { return _vm.tabLoad('3', pageInfo); }}},_vm._l((_vm.userList),function(rgb,wo){return _c('div',{key:wo,staticClass:"bottom-transaction"},[_c('p',[_vm._v(_vm._s(rgb.name))])])}),0)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }