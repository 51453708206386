<template>
  <div class="order-management">
    <PageBread
      text="我的优惠券"
      :img="() => require('../../assets/image/zhuye.png')"
    ></PageBread>
    <div class="center-order">
      <PageTab
        style="height: calc(100vh - 200px)"
        @change="tabChange"
        :current="tabCurrent"
        :tabs="[
          {
            label: '未使用',
            value: 1,
          },
          {
            label: '已过期',
            value: 2,
          },
          {
            label: '已使用',
            value: 3,
          },
        ]"
      >
        <!-- 放第一栏内容 -->
        <ScrollView
          v-show="tabCurrent === 0"
          :pageInfo="tab1PageInfo"
          @loadNewData="(pageInfo) => tabLoad('1', pageInfo)"
        >
          <div
            class="bottom-transaction"
            v-for="(item, index) in noUserList"
            :key="index"
          >
            <p>{{ item.name }}</p>
          </div>
        </ScrollView>
        <!-- 放第二栏内容 -->
        <ScrollView
          v-show="tabCurrent === 1"
          :pageInfo="tab2PageInfo"
          @loadNewData="(pageInfo) => tabLoad('2', pageInfo)"
        >
          <div
            class="bottom-transaction"
            v-for="(exo, jm) in expiredList"
            :key="jm"
          >
            <p>{{ exo.name }}</p>
          </div>
        </ScrollView>
        <!-- 放第三栏内容 -->
        <ScrollView
          v-show="tabCurrent === 2"
          :pageInfo="tab3PageInfo"
          @loadNewData="(pageInfo) => tabLoad('3', pageInfo)"
        >
          <div
            class="bottom-transaction"
            v-for="(rgb, wo) in userList"
            :key="wo"
          >
            <p>{{ rgb.name }}</p>
          </div>
        </ScrollView>
      </PageTab>
    </div>
  </div>
</template>
<script>
import { couponInfo } from '../../utils/coupon.js'
export default {
  name: 'login',
  data() {
    return {
      tabCurrent: 0,
      showDetail: false,
      activeName: '',
      noUserList: [],
      expiredList: [],
      userList: [],
      tab1PageInfo: {
        hasNextPage: true,
        pageSize: 15,
        pageNum: 1
      },
      tab2PageInfo: {
        hasNextPage: true,
        pageSize: 15,
        pageNum: 1
      },
      tab3PageInfo: {
        hasNextPage: true,
        pageSize: 15,
        pageNum: 1
      }
    }
  },
  created() {
    this.tabLoad('1', this.tab1PageInfo)
    this.tabLoad('2', this.tab2PageInfo)
    this.tabLoad('3', this.tab3PageInfo)
  },
  mounted() {},
  methods: {
    tabChange(index, item) {
      this.tabCurrent = index
    },
    tabLoad(type, pageInfo) {
      this._getList(type, pageInfo)
    },

    _getList(type, pageInfo) {
      const params = {
        id: JSON.parse(localStorage.userInfo).id,
        type: type,
        ...pageInfo
      }
      this.$reqPost(couponInfo.list, params).then((res) => {
        if (res.data.code === 200) {
          if (type === '1') {
            this.noUserList = [...this.noUserList, ...res.data.data.list]
            this.tab1PageInfo.hasNextPage = res.data.data.hasNextPage
          }
          if (type === '2') {
            this.expiredList = [...this.expiredList, ...res.data.data.list]
            this.tab2PageInfo.hasNextPage = res.data.data.hasNextPage
          }
          if (type === '3') {
            this.userList = [...this.userList, ...res.data.data.list]
            this.tab3PageInfo.hasNextPage = res.data.data.hasNextPage
          }
        }
      })
      // const paramsed = {
      //   id: JSON.parse(localStorage.userInfo).id,
      //   type: type,
      //   ...pageInfo
      // };
      // this.$reqPost(couponInfo.list, paramsed).then((res) => {
      //   if (res.data.code === 200) {
      //     this.expiredList = res.data.data.list;
      //     this.total = res.data.data.total;
      //     this.pageNum = res.data.data.pageNum;
      //     this.pageSize = res.data.data.pageSize;
      //   }
      // });
      // const param = {
      //   id: JSON.parse(localStorage.userInfo).id,
      //   type: type,
      //   ...pageInfo
      // };
      // this.$reqPost(couponInfo.list, param).then((res) => {
      //   if (res.data.code === 200) {
      //     this.userList = res.data.data.list;
      //     this.total = res.data.data.total;
      //     this.pageNum = res.data.data.pageNum;
      //     this.pageSize = res.data.data.pageSize;
      //   }
      // });
    },
    currentPageChange(pageNum) {
      // this.pageNum = pageNum;
    }
  }
}
</script>
<style lang="less" scoped>
@import url("../order-management/order.less");
.pagination {
  margin-top: -60px;
}
</style>
